// src/utils/googlePhotosApi.js

import axios from 'axios';
import moment from 'moment';
import { doc, getDoc } from "firebase/firestore";
import { db, auth } from "../firebase";

let token = null;
let retryLogin = null;

export const initializeGooglePhotosApi = (initialToken, initialRetryLogin) => {
  token = initialToken;
  retryLogin = initialRetryLogin;
};

const retryAPICall = async (apiCall, retryCount = 0) => {
  /*
  if (retryCount === 0) {
    const newToken = await retryLogin();
      if (newToken) {
        token = newToken;  // Update the token with the new one
        return await retryAPICall(apiCall, retryCount + 1);
      }
      else {
        return null;
      }
  }
  */
  if (retryCount >= 3) {
    console.error('Retry limit exceeded. API call failed.');
    return null;
  }

  try {
    const result = await apiCall();
    return result;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      console.error('Token expired or invalid. Re-authenticating...');
      const newToken = await retryLogin();
      if (newToken) {
        token = newToken;  // Update the token with the new one
        return await retryAPICall(apiCall, retryCount + 1);
      } else {
        console.error('Retry login failed. API call failed.');
        return null;
      }
    } else {
      console.error('Error in API call:', error);
      return null;
    }
  }
};

export const fetchPhotoGeoLocation = async (userId, photoId, otherUserId = null) => {
  let photoDoc = await getDoc(doc(db, `users/${userId}/photos/${photoId}`));
  if (photoDoc.exists()) {
    return photoDoc.data();
  }

  // If the photo does not exist in the current user's collection, check another user's collection
  if (otherUserId) {
    photoDoc = await getDoc(doc(db, `users/${otherUserId}/photos/${photoId}`));
    if (photoDoc.exists()) {
      return photoDoc.data();
    }
  }

  return null;
};

export const fetchDailyPhotos = async (date, otherUserId = null) => {
  const user = auth.currentUser;
  if (user) {
    const userId = user.uid;
    const formattedDate = moment(date).format('YYYY-MM-DD');
    const [year, month, day] = formattedDate.split('-').map(Number);
    const dateFilter = { year, month, day };

    const apiCall = async () => {
      const response = await axios.post(
        'https://photoslibrary.googleapis.com/v1/mediaItems:search',
        {
          filters: {
            dateFilter: {
              dates: [dateFilter],
            },
          },
          pageSize: 100
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const dailyPhotos = response.data.mediaItems || [];
      for (const photo of dailyPhotos) {
        const photoGeoData = await fetchPhotoGeoLocation(userId, photo.filename, otherUserId);
        if (photoGeoData) {
          photo.latitude = photoGeoData.latitude;
          photo.longitude = photoGeoData.longitude;
        }
        // Add date_taken field
        if (photo.mediaMetadata && photo.mediaMetadata.creationTime) {
          photo.date_taken = photo.mediaMetadata.creationTime;
        }
      }

      // Additional processing for photos without geotag data
      for (let i = 0; i < dailyPhotos.length; i++) {
        const photo = dailyPhotos[i];
        if (!photo.latitude || !photo.longitude) {
          let closestPhoto = null;
          let smallestTimeDiff = Infinity;

          for (let j = 0; j < dailyPhotos.length; j++) {
            if (i !== j) {
              const otherPhoto = dailyPhotos[j];
              if (otherPhoto.latitude && otherPhoto.longitude) {
                const timeDiff = Math.abs(new Date(photo.date_taken) - new Date(otherPhoto.date_taken));
                const timeDiffMinutes = timeDiff / (1000 * 60);

                if (timeDiffMinutes <= 5 && timeDiffMinutes < smallestTimeDiff) {
                  closestPhoto = otherPhoto;
                  smallestTimeDiff = timeDiffMinutes;
                }
              }
            }
          }

          if (closestPhoto) {
            photo.latitude = closestPhoto.latitude;
            photo.longitude = closestPhoto.longitude;
          }
        }
      }

      return dailyPhotos;
    };

    return await retryAPICall(apiCall);
  }
  return [];
};

export const fetchPhotosByIds = async (photoIds) => {
  if (!photoIds.length) return [];

  const apiCall = async () => {
    const params = photoIds.map(id => `mediaItemIds=${id}`).join('&');
    const url = `https://photoslibrary.googleapis.com/v1/mediaItems:batchGet?${params}`;

    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data.mediaItemResults.map((item) => {
      const photo = item.mediaItem;
      // Add date_taken field
      if (photo.mediaMetadata && photo.mediaMetadata.creationTime) {
        photo.date_taken = photo.mediaMetadata.creationTime;
      }
      return photo;
    });
  };

  return await retryAPICall(apiCall);
};
