// src/components/PhotoPopupWithGeo.js

import React, { useState, useEffect, useCallback, useRef } from 'react';
import './PhotoPopupWithGeo.css';
import { distanceBetweenCoordinates } from '../utils/distanceUtils';
import Autocomplete from '@mui/material/Autocomplete';
import { TextField, CircularProgress } from '@mui/material';
import { debounce } from 'lodash';

const PhotoPopupWithGeo = ({ photos, closePhotoPopup, fetchMorePlacesForGroup, fetchPlaceForGroup }) => {
  const [updatedPhotos, setUpdatedPhotos] = useState([]);
  const [loading, setLoading] = useState(false);
  const requestIdRef = useRef(0);

  useEffect(() => {
    setUpdatedPhotos(photos);
  }, [photos]);

  const handlePlaceChange = async(photo, placeId) => {
    const place = photo.places.find(pl => pl.place_id === placeId);

    if (placeId === 'show_more') {
      await fetchMorePlacesForGroup(photo, photo.places.find(pl => pl.place_id === 'show_more').nextPageToken);
      setUpdatedPhotos([...photos]); // Re-trigger the re-render with the updated photos
      return;
    }

    photos.forEach(p => {
      if (p.id === photo.id || (distanceBetweenCoordinates(p, photo) <= 10 && (!p.place || !p.place.place_id))) {
        p.place = place ? {
          place_id: place.place_id,
          name: place.name,
          types: place.types
        } : undefined;
      }
    });

    // Set the updated photos to trigger a re-render
    setUpdatedPhotos([...photos]); // Re-trigger the re-render with the updated photos
  };

  const handleSearchChange = useCallback(async (photo, text) => {
    setLoading(true);
    const currentRequestId = ++requestIdRef.current;

    try {
      const result = await fetchPlaceForGroup(photo, null, text);

      // Only update state if this is the most recent request
      if (currentRequestId === requestIdRef.current) {
        setUpdatedPhotos(prevPhotos =>
          prevPhotos.map(p =>
            p.id === photo.id ? { ...p, places: result.places } : p
          )
        );
        setLoading(false);
      }
    } catch (error) {
      console.error('Fetch error:', error);
      if (currentRequestId === requestIdRef.current) {
        setLoading(false);
      }
    }
  }, [fetchPlaceForGroup]);

  const debouncedHandleSearchChange = useCallback((photo, text) => {
    const debouncedFn = debounce((p, t) => {
      handleSearchChange(p, t);
    }, 1000);
    debouncedFn(photo, text);
    
    // Cleanup function to cancel the debounce on unmount or re-render
    return () => {
      debouncedFn.cancel();
    };
  }, [handleSearchChange]);

  const onInputChange = (photo, newInputValue) => {
    debouncedHandleSearchChange(photo, newInputValue);
  };

  return (
    <div className="popup" onClick={closePhotoPopup}>
      <div className="popup-content" onClick={(e) => e.stopPropagation()}>
        <div className="photo-list">
          {updatedPhotos.map(photo => (
            <div key={photo.id} className="photo-item">
              <img src={photo.baseUrl} alt={photo.filename} />
              <Autocomplete
                options={photo.places || []}
                getOptionLabel={(option) => option.name || ""}
                isOptionEqualToValue={(option, value) => option.place_id === value.place_id}
                onInputChange={(event, newInputValue) => onInputChange(photo, newInputValue)}
                onChange={(event, newValue) => handlePlaceChange(photo, newValue ? newValue.place_id : '')}
                renderInput={(params) => (
                  <div style={{ position: 'relative', width: '80%' }}>
                    <TextField {...params} label="Search places" variant="outlined" />
                    {loading && <CircularProgress size={24} style={{ position: 'absolute', top: '50%', right: 0, marginTop: -12, marginRight: 10 }} />}
                  </div>
                )}
                value={photo.place || null}
                style={{ width: '80%' }} // Set the width of the Autocomplete box
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PhotoPopupWithGeo;
