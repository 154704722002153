// src/utils/distanceUtils.js
export const distanceBetweenCoordinates = (photo1, photo2) => {
  const R = 6371e3; // Earth radius in meters
  const lat1 = photo1.latitude * (Math.PI / 180);
  const lat2 = photo2.latitude * (Math.PI / 180);
  const deltaLat = (photo2.latitude - photo1.latitude) * (Math.PI / 180);
  const deltaLon = (photo2.longitude - photo1.longitude) * (Math.PI / 180);

  const a =
    Math.sin(deltaLat / 2) * Math.sin(deltaLat / 2) +
    Math.cos(lat1) * Math.cos(lat2) *
    Math.sin(deltaLon / 2) * Math.sin(deltaLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  const distance = R * c;
  return distance;
};
