import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Box, Typography } from "@mui/material";
import { fetchEntries } from "../entriesApi";

const CalendarView = ({ selectedDate, setSelectedDate, setPhotoBrowsingDate }) => {
  const [markedDates, setMarkedDates] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const entries = await fetchEntries();
      setMarkedDates(entries.map((entry) => entry.createdAt.toDate().toDateString()));
    };

    fetchData();
  }, []);

  const handleDateSelect = (date) => {
    setSelectedDate(date);
    setPhotoBrowsingDate(date);
  };

  const highlightWithRanges = [
    {
      "react-datepicker__day--highlighted-custom-1": markedDates.map((date) => new Date(date)),
    },
  ];

  return (
    <Box sx={{ mt: 3 }}>
      <Typography variant="h5" component="div">
        Calendar
      </Typography>
      <DatePicker
        selected={selectedDate}
        onChange={(date) => handleDateSelect(date)}
        highlightDates={highlightWithRanges}
        inline
      />
    </Box>
  );
};

export default CalendarView;
