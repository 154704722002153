// src/components/JournalEntry.js

import React, { useState } from "react";
import { TextField, Button, Box, Typography, Slider, Card, CardContent } from "@mui/material";
import { db, auth } from "../firebase";
import { doc, getDoc } from "firebase/firestore";
import axios from "axios";
import PhotoPopup from "./PhotoPopup";
import DiffPopup from "./DiffPopup"; // Import the DiffPopup component
import './JournalEntry.css';

const JournalEntry = ({
  selectedDate,
  currentEntry,
  setCurrentEntry,
  setLoading,
  setAnalysisResult,
  analysisResult,
  saveEntry,
  openGeoPhotoPopup  // Receive the handler as a prop
}) => {
  const [popupPhotos, setPopupPhotos] = useState(null);
  const [showDiffPopup, setShowDiffPopup] = useState(false); // State to manage DiffPopup visibility

  const analyzeText = async () => {
    setLoading(true);
    await saveEntry();
    const tokenDoc = await getDoc(doc(db, "tokens", auth.currentUser.uid));
    const chatGptToken = tokenDoc.data().token;
    const response = await axios.post(
      "https://api.openai.com/v1/chat/completions",
      {
        model: "gpt-4o",
        response_format: { "type": "json_object" },
        messages: [
          {
            role: "system",
            content: "You are a helpful assistant that analyzes journal entries, designed to output JSON."
          },
          {
            role: "user",
            content: `Analyze the following journal entry and provide the following details in JSON format: title, corrected (correct any spelling or grammar issues, try to keep the writer style as is), keywords (as an array), mood score from 1 to 5, feedback with suggestions for improvement, and questions to promote further writing. Return a valid JSON format: { "title": "string", "corrected": "string", "keywords": ["string"], "mood": number, "feedback": "string", "questions": ["string"] }.  Here is the journal entry: "${currentEntry.content}"`
          }
        ],
        max_tokens: 3000,
      },
      {
        headers: {
          Authorization: `Bearer ${chatGptToken}`,
        },
      }
    );

    const analysis = JSON.parse(response.data.choices[0].message.content);

    // Update currentEntry conditionally
    setCurrentEntry(prevEntry => ({
      ...prevEntry,
      title: prevEntry.title || analysis.title,
      keywords: prevEntry.keywords.length === 0 ? analysis.keywords : prevEntry.keywords,
      mood: prevEntry.mood === 3 ? analysis.mood : prevEntry.mood,
    }));

    setAnalysisResult(analysis); // Set the analysis result in the App state
    setShowDiffPopup(true); // Show the DiffPopup
    setLoading(false);
  };

  const openPhotoPopup = () => {
    setPopupPhotos(currentEntry.photos);
  };

  const closePhotoPopup = () => {
    setPopupPhotos(null);
  };

  const handleGeoPopupOpen = () => {
    const photosWithGeo = currentEntry.photos.filter(
      photo => photo.latitude && photo.longitude && photo.latitude !== 0 && photo.longitude !== 0
    );
    openGeoPhotoPopup(photosWithGeo);
  };

  const photosWithGeoExist = currentEntry.photos.some(
    photo => photo.latitude && photo.longitude && photo.latitude !== 0 && photo.longitude !== 0
  );

  const handleAccept = () => {
    if (analysisResult) {
      setCurrentEntry(prevEntry => ({
        ...prevEntry,
        content: analysisResult.corrected, // Update the entry with the corrected text
      }));
    }
    setShowDiffPopup(false); // Hide the DiffPopup
  };

  const handleReject = () => {
    setShowDiffPopup(false); // Hide the DiffPopup
  };

  return (
    <Card sx={{ mt: 3 }}>
      <CardContent>
        <Typography variant="h5" component="div">
          Journal Entry
        </Typography>
        <Typography color="text.secondary" gutterBottom>
          {currentEntry && currentEntry.createdAt 
            ? currentEntry.createdAt.toDate().toLocaleString() 
            : selectedDate.toLocaleString()}
        </Typography>
        <TextField
          label="Title"
          variant="outlined"
          fullWidth
          value={currentEntry?.title || ""}
          onChange={(e) => setCurrentEntry({ ...currentEntry, title: e.target.value })}
          sx={{ mb: 2 }}
        />
        <TextField
          label="What's on your mind?"
          variant="outlined"
          multiline
          rows={4}
          fullWidth
          value={currentEntry?.content || ""}
          onChange={(e) => setCurrentEntry({ ...currentEntry, content: e.target.value })}
          sx={{ mb: 2 }}
        />
        <Box sx={{ mb: 2 }}>
          <Button variant="contained" component="label">
            Upload Image
            <input type="file" hidden onChange={(e) => setCurrentEntry({ ...currentEntry, image: URL.createObjectURL(e.target.files[0]) })} />
          </Button>
          {currentEntry?.image && <img src={currentEntry.image} alt="Journal" style={{ marginTop: 10, maxWidth: "100%" }} />}
        </Box>
        <TextField
          label="Keywords"
          variant="outlined"
          fullWidth
          value={currentEntry?.keywords.join(", ") || ""}
          onChange={(e) => setCurrentEntry({ ...currentEntry, keywords: e.target.value.split(", ") })}
          sx={{ mb: 2 }}
        />
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <Typography variant="body1" sx={{ mr: 2 }}>Mood:</Typography>
          <Slider
            value={currentEntry?.mood || 3}
            min={1}
            max={5}
            step={1}
            marks
            onChange={(e, newValue) => setCurrentEntry({ ...currentEntry, mood: newValue })}
            valueLabelDisplay="auto"
          />
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button variant="contained" color="secondary" onClick={analyzeText}>
            Analyze Text
          </Button>
          <Button variant="contained" color="primary" onClick={saveEntry}>
            Save
          </Button>
        </Box>
        {currentEntry?.photos?.length > 0 && (
          <Box sx={{ mt: 2 }}>
            <Typography variant="h6" component="div">
              Selected Photos
            </Typography>
            <div className="photos-grid">
              {currentEntry.photos.map((photo, index) => (
                <div key={`selected-${photo.id || index}`} className="photo-item">
                  <img 
                    src={photo.baseUrl} 
                    alt={photo.filename} 
                    style={{ maxWidth: "100px", maxHeight: "100px", margin: "5px" }} 
                    onClick={openPhotoPopup}
                  />
                </div>
              ))}
            </div>
          </Box>
        )}
        {photosWithGeoExist && (
          <Button onClick={handleGeoPopupOpen} variant="contained" color="primary">
            Set Places
          </Button>
        )}
      </CardContent>
      {popupPhotos && (
        <PhotoPopup 
          photos={popupPhotos} 
          closePhotoPopup={closePhotoPopup} 
          currentEntry={currentEntry}
          setCurrentEntry={setCurrentEntry}
        />
      )}
      {showDiffPopup && analysisResult && (
        <DiffPopup 
          original={currentEntry.content}
          corrected={analysisResult.corrected}
          onAccept={handleAccept}
          onReject={handleReject}
        />
      )}
    </Card>
  );
};

export default JournalEntry;
