// src/firebase.js
import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider, signInWithPopup, onAuthStateChanged } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getFunctions, httpsCallable } from 'firebase/functions';

const firebaseConfig = {
  apiKey: "AIzaSyCQkqH1JhD63W5-mTzwsnEsCOPGBl9Nv4E",
  authDomain: "journal-f0b58.firebaseapp.com",
  projectId: "journal-f0b58",
  storageBucket: "journal-f0b58.appspot.com",
  messagingSenderId: "603994261515",
  appId: "1:603994261515:web:5752ad5382ae9b29a65f0e"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const functions = getFunctions(app);

const provider = new GoogleAuthProvider();
provider.addScope('https://www.googleapis.com/auth/photoslibrary.readonly');

export { auth, db, functions, provider, signInWithPopup, onAuthStateChanged, httpsCallable };
