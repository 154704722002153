// src/components/JournalAssistant.js
import React from "react";
import { Card, CardContent, Typography, Box } from "@mui/material";

const JournalAssistant = ({ analysis }) => {
  return (
    <Card sx={{ mt: 3 }}>
      <CardContent>
        <Typography variant="h5" component="div">
          My Journal Assistant
        </Typography>
        {analysis ? (
          <Box>
            <Typography variant="h6" component="div" sx={{ mt: 2 }}>
              Feedback
            </Typography>
            <Typography variant="body1" component="div">
              {analysis.feedback}
            </Typography>
            <Typography variant="h6" component="div" sx={{ mt: 2 }}>
              Suggestions
            </Typography>
            <Typography variant="body1" component="div">
              {analysis.questions}
            </Typography>
            {analysis.generatedImage && (
              <Box sx={{ mt: 2 }}>
                <Typography variant="h6" component="div">
                  Generated Image
                </Typography>
                <img src={analysis.generatedImage} alt="Generated description" style={{ width: '100%', marginTop: 10 }} />
              </Box>
            )}
          </Box>
        ) : (
          <Typography variant="body1" component="div">
            No analysis available. Please analyze your journal entry.
          </Typography>
        )}
      </CardContent>
    </Card>
  );
};

export default JournalAssistant;
