// src/components/LoadingAnimation.js
import React from 'react';
import Lottie from 'lottie-react';
import animationData from '../assets/wait_anim.json';
import './LoadingAnimation.css';

const LoadingAnimation = () => {
  return (
    <div className="loading-overlay">
      <Lottie animationData={animationData} />
    </div>
  );
};

export default LoadingAnimation;
