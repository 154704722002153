// src/components/PhotoPopup.js
import React from 'react';
import './PhotoPopup.css';

const PhotoPopup = ({ photos, closePhotoPopup, currentEntry, setCurrentEntry }) => {
  if (!photos || photos.length === 0) return null;

  const handleCheckboxChange = (photo) => {
    setCurrentEntry(prevEntry => ({
      ...prevEntry,
      photos: prevEntry.photos.some(p => p.id === photo.id)
        ? prevEntry.photos.filter(p => p.id !== photo.id)
        : [...prevEntry.photos, photo],
    }));
  };

  return (
    <div className="popup" onClick={closePhotoPopup}>
      <div className="popup-content" onClick={(e) => e.stopPropagation()}>
        <div className="photo-list">
          {photos.map(photo => (
            <div key={photo.id} className="photo-item">
              <img src={photo.baseUrl} alt={photo.filename} className="popup-image" />
              <label className="select-checkbox">
                <input
                  type="checkbox"
                  checked={currentEntry.photos.some(p => p.id === photo.id)}
                  onChange={() => handleCheckboxChange(photo)}
                />
              </label>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PhotoPopup;
