// src/App.js

import React, { useState, useEffect, useCallback } from "react";
import { auth, onAuthStateChanged, db, provider, signInWithPopup, functions, httpsCallable } from "./firebase";
import { GoogleAuthProvider } from "firebase/auth";
import { doc, getDoc, getDocs, setDoc, updateDoc, addDoc, collection } from "firebase/firestore";
import { Container, Button, Grid, Typography, Box, TextField, Snackbar, Alert } from "@mui/material";
import CalendarView from "./components/CalendarView";
import JournalEntry from "./components/JournalEntry";
import JournalAssistant from "./components/JournalAssistant";
import LoadingAnimation from "./components/LoadingAnimation";
import PhotoPopup from "./components/PhotoPopup";
import PhotoPopupWithGeo from "./components/PhotoPopupWithGeo";
import moment from 'moment';
import './App.css';
import { fetchEntryByDate } from "./entriesApi";
import { initializeGooglePhotosApi, fetchDailyPhotos as fetchDailyPhotosFromApi } from "./utils/googlePhotosApi";
import { distanceBetweenCoordinates } from './utils/distanceUtils'; // Import the distance utility function
import MapIcon from './assets/map_icon.png';

const App = () => {
  const [user, setUser] = useState(null);
  const [otherUserId, setOtherUserId] = useState(null);
  const [chatGptToken, setChatGptToken] = useState("");
  const [token, setToken] = useState(localStorage.getItem('googleAccessToken') || null);
  const [refreshToken, setRefreshToken] = useState(localStorage.getItem('googleRefreshToken') || null);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [photoBrowsingDate, setPhotoBrowsingDate] = useState(new Date());
  const [dailyPhotos, setDailyPhotos] = useState([]);
  const [currentEntry, setCurrentEntry] = useState({
    title: "",
    content: "",
    keywords: [],
    mood: 3,
    image: "",
    photos: []
  });
  const [popupPhoto, setPopupPhoto] = useState(null);
  const [loading, setLoading] = useState(false);
  const [analysisResult, setAnalysisResult] = useState(null);
  const [saveStatus, setSaveStatus] = useState({ visible: false, message: "", success: true });
  const [geoPopupPhotos, setGeoPopupPhotos] = useState(null);
  const [showTokenInput, setShowTokenInput] = useState(true); // Add state to manage visibility of token input box

  // Fetch the otherUserId from the new share subcollection
  const fetchOtherUserId = async () => {
    const user = auth.currentUser;
    /*
    if (user) {
      const specificDocRef = doc(db, `users/${user.uid}/share/HqZeLHkWI7QVpIRQDiNpMOPhSrB3`);
      const docSnap = await getDoc(specificDocRef);
      if (docSnap.exists()) {
        console.log("Document data:", docSnap.data());
      } else {
        console.log("No such document!");
      }
    }
    */

    if (user) {
      const shareCollectionRef = collection(db, `users/${user.uid}/share`);
      const shareSnapshot = await getDocs(shareCollectionRef);

      if (!shareSnapshot.empty) {
        // Assuming there is only one document in the share subcollection per user
        const firstDoc = shareSnapshot.docs[0];
        return firstDoc.id;  // This will be the UID of the other user
      }
    }
    return null;
  };

  const groupPhotosByDistance = (photos, distanceThreshold) => {
    const groups = [];
    photos.forEach(photo => {
      let addedToGroup = false;
      for (const group of groups) {
        if (group.some(p => distanceBetweenCoordinates(p, photo) <= distanceThreshold)) {
          group.push(photo);
          addedToGroup = true;
          break;
        }
      }
      if (!addedToGroup) {
        groups.push([photo]);
      }
    });
    return groups;
  };

  const fetchPlaceForGroup = async (photo, pageToken = null, text = '') => {
    const getNearbyPlaces = httpsCallable(functions, 'getNearbyPlaces');
    try {
      const result = await getNearbyPlaces({ lat: photo.latitude, lng: photo.longitude, pageToken, query: text });
      const places = result.data.places;
  
      if (result.data.nextPageToken) {
        places.push({
          name: 'Show more results',
          place_id: 'show_more',
          nextPageToken: result.data.nextPageToken,
        });
      }
  
      return { places };
    } catch (error) {
      console.error('Error fetching nearby places:', error);
      return { places: [] };
    }
  };

  const fetchMorePlacesForGroup = async (photo, token) => {
    // Find the group this photo is part of
    const group = currentEntry.photos.filter(p => distanceBetweenCoordinates(p, photo) <= 10);
    
    // Fetch more places using the token
    const result = await fetchPlaceForGroup(photo, token);
    
    // Add new places to all photos in the group
    group.forEach(p => {
      p.places = [
        ...p.places.filter(place => place.place_id !== 'show_more'),
        ...result.places
      ];
    });

    setCurrentEntry({
      ...currentEntry,
      photos: [...currentEntry.photos]
    });
  };

  const fetchDailyPhotos = useCallback(async (date, accessToken = token) => {
    if (!user) return;
    setLoading(true);

    try {
      const photosWithGeoLocation = await fetchDailyPhotosFromApi(date, otherUserId);

      // Sort photos by date_taken in ascending order
      const sortedPhotos = photosWithGeoLocation.sort((a, b) => new Date(a.date_taken) - new Date(b.date_taken));

      setDailyPhotos(sortedPhotos || []);
    } catch (error) {
      console.error('Error fetching photos:', error);
      setDailyPhotos([]); // Ensure dailyPhotos is always an array
    } finally {
      setLoading(false);
    }
  }, [token, user, otherUserId]);

  const saveEntry = async () => {
    const user = auth.currentUser;
    if (user) {
      try {
        const photoObjects = currentEntry.photos.map(photo => {
          const photoObj = {
            date_taken: photo.date_taken,
            filename: photo.filename,
            id: photo.id
          };
          if (photo.latitude && photo.longitude) {
            photoObj.latitude = photo.latitude;
            photoObj.longitude = photo.longitude;
          }
          if (photo.place) {
            photoObj.place = photo.place;
          }
          return photoObj;
        });

        if (currentEntry && currentEntry.id) {
          const entryDoc = doc(db, `users/${user.uid}/entries`, currentEntry.id);
          await updateDoc(entryDoc, {
            ...currentEntry,
            updatedAt: new Date(),
            photos: photoObjects,
          });
        } else if (currentEntry.title || currentEntry.content || currentEntry.keywords.length > 0 || currentEntry.image) {
          const newDocRef = await addDoc(collection(db, `users/${user.uid}/entries`), {
            userId: user.uid,
            ...currentEntry,
            createdAt: new Date(selectedDate),
            photos: photoObjects,
          });
          setCurrentEntry({ ...currentEntry, id: newDocRef.id });
        }
        setSaveStatus({ visible: true, message: "Save successful!", success: true });
      } catch (error) {
        setSaveStatus({ visible: true, message: "Save failed!", success: false });
      }
    }
  };

  const handleDateSelect = useCallback(async (date) => {
    if (!user) return;
    setSelectedDate(date);
    let entry = await fetchEntryByDate(date);

    if (entry) {
      setCurrentEntry(entry);
    } else {
      setCurrentEntry({
        title: "",
        content: "",
        keywords: [],
        mood: 3,
        image: "",
        photos: []
      });
    }
  }, [user]);

  const handlePhotoBrowsingDateChange = useCallback(async (date) => {
    if (!user) return;
    setPhotoBrowsingDate(date);
    await fetchDailyPhotos(date);
  }, [fetchDailyPhotos, user]);

  const handleLogin = async () => {
    try {
      provider.setCustomParameters({
        access_type: 'offline',
      });
      const result = await signInWithPopup(auth, provider);
      const credential = GoogleAuthProvider.credentialFromResult(result);
      const accessToken = credential.accessToken;
      const refreshToken = null; //result._tokenResponse.refreshToken;
      localStorage.setItem('googleAccessToken', accessToken);
      localStorage.setItem('googleRefreshToken', refreshToken);
      setToken(accessToken);
      setRefreshToken(refreshToken);
      return accessToken;
    } catch (error) {
      console.error('Login failed:', error);
      return null;
    }
  };

  
  useEffect(() => {
    const refreshGoogleToken = async () => {
      if (!refreshToken) {
        return await handleLogin();
      }
      const refreshTokenFunc = httpsCallable(functions, 'refreshToken');
      try {
        const response = await refreshTokenFunc({ refreshToken });
        const newToken = response.data.token;
        const newRefreshToken = response.data.refreshToken;
        localStorage.setItem('googleAccessToken', newToken);
        localStorage.setItem('googleRefreshToken', newRefreshToken);
        setToken(newToken);
        setRefreshToken(newRefreshToken);
        return newToken;
      } catch (error) {
        console.error('Token refresh failed, fallback to login:', error);
        return await handleLogin();
      }
    };
  
    const fetchInitialData = async (accessToken) => {
      initializeGooglePhotosApi(accessToken, refreshGoogleToken);
      await handleDateSelect(new Date());
      await fetchDailyPhotos(new Date(), token);
    };

    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      setUser(user);
      if (user) {
        const otherUserId = await fetchOtherUserId();  // Fetch the other user ID
        if (otherUserId) {
          setOtherUserId(otherUserId);  // Store it in the state
        }
        const tokenDoc = await getDoc(doc(db, "tokens", user.uid));
        if (tokenDoc.exists()) {
          setChatGptToken(tokenDoc.data().token);
          setShowTokenInput(false); // Hide the input box after saving the token
        }
        await fetchInitialData(token);
      }
    });
    return () => unsubscribe();
  }, [handleDateSelect, fetchDailyPhotos, token, refreshToken]);

  const handleDayChange = (days) => {
    const newDate = moment(photoBrowsingDate).add(days, 'days').toDate();
    handlePhotoBrowsingDateChange(newDate);
  };


  const saveToken = async () => {
    if (chatGptToken && auth.currentUser) {
      try {
        await setDoc(doc(db, "tokens", auth.currentUser.uid), {
          token: chatGptToken,
        });
        alert("Token saved successfully!");
        setShowTokenInput(false); // Hide the input box after saving the token
      } catch (error) {
        console.error("Error saving token: ", error);
        alert("Failed to save token.");
      }
    }
  };

  const openPhotoPopup = () => {
    setPopupPhoto(dailyPhotos);
  };

  const closePhotoPopup = () => {
    setPopupPhoto(null);
  };

  const handleGeoPopupOpen = async () => {
    setLoading(true);
    const photosWithGeo = currentEntry.photos.filter(
      photo => photo.latitude && photo.longitude && photo.latitude !== 0 && photo.longitude !== 0
    );

    const groups = groupPhotosByDistance(photosWithGeo, 10); // Group photos by 10 meters

    const placePromises = groups.map(async group => {
      const response = await fetchPlaceForGroup(group[0]); // Make the API call for the first photo in the group
      return { group, places: response.places }; // Store the group and places result
    });

    const placeResults = await Promise.all(placePromises);

    const updatedPhotos = currentEntry.photos.map(photo => {
      const result = placeResults.find(result =>
        result.group.some(p => p.id === photo.id)
      );

      if (result) {
        // If the photo has a place that is not in the fetched places, add it as the first entry
        if (photo.place && !result.places.some(pl => pl.place_id === photo.place.place_id)) {
          result.places = [photo.place, ...result.places];
        }
        return { ...photo, places: result.places };
      }

      return photo;
    });

    setCurrentEntry(prevEntry => ({
      ...prevEntry,
      photos: updatedPhotos,
    }));

    setLoading(false);
    openGeoPhotoPopup(updatedPhotos);
  };

  const openGeoPhotoPopup = (photos) => {
    setGeoPopupPhotos(photos);
  };

  const closeGeoPhotoPopup = () => {
    setGeoPopupPhotos(null);
  };

  return (
    <Container>
      {loading && <LoadingAnimation />}
      {!user ? (
        <Button onClick={handleLogin} variant="contained" color="primary">
          Login with Google
        </Button>
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={12} md={8}>
            {showTokenInput && (
              <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                <TextField
                  label="Enter ChatGPT Token"
                  variant="outlined"
                  value={chatGptToken}
                  onChange={(e) => setChatGptToken(e.target.value)}
                  sx={{ mb: 2, width: "300px" }}
                />
                <Button variant="contained" color="primary" onClick={saveToken}>
                  Save Token
                </Button>
              </Box>
            )}
            <JournalAssistant analysis={analysisResult} />
            <JournalEntry
              selectedDate={selectedDate}
              currentEntry={currentEntry}
              setCurrentEntry={setCurrentEntry}
              setLoading={setLoading}
              setAnalysisResult={setAnalysisResult}
              analysisResult={analysisResult}
              saveEntry={saveEntry}
              openGeoPhotoPopup={handleGeoPopupOpen} // Pass the handler as a prop
            />
            <CalendarView
              selectedDate={selectedDate}
              setSelectedDate={handleDateSelect}
              setPhotoBrowsingDate={handlePhotoBrowsingDateChange}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Box sx={{ mt: 3 }}>
              <Typography variant="h6" component="div">
                Photos from {moment(photoBrowsingDate).format('MMMM Do YYYY')}
              </Typography>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                <Button variant="outlined" onClick={() => handleDayChange(-1)}>←</Button>
                <Button variant="outlined" onClick={() => handleDayChange(1)}>→</Button>
              </Box>
              {dailyPhotos.length > 0 ? (
                <div className="photos-container">
                  <div className="photos-grid">
                    {dailyPhotos.map((photo) => (
                      <div key={`daily-${photo.id}`} className="photo-item">
                        <img src={photo.baseUrl} alt={photo.filename} onClick={openPhotoPopup} />
                        {photo.latitude && photo.longitude && (
                          <img
                            src={MapIcon}
                            alt="map"
                            style={{
                              position: 'absolute',
                              top: '0px',
                              right: '0px',
                              width: '25px',
                              height: '25px',
                            }}
                          />
                        )}
                        <label className="select-checkbox">
                          <input
                            type="checkbox"
                            checked={currentEntry.photos.some(p => p.id === photo.id)}
                            onChange={() => setCurrentEntry({
                              ...currentEntry,
                              photos: currentEntry.photos.some(p => p.id === photo.id)
                                ? currentEntry.photos.filter(p => p.id !== photo.id)
                                : [...currentEntry.photos, photo]
                            })}
                          />
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
              ) : (
                <Typography>No photos available for this date.</Typography>
              )}
              {popupPhoto && (
                <PhotoPopup
                  photos={popupPhoto}
                  closePhotoPopup={closePhotoPopup}
                  currentEntry={currentEntry}
                  setCurrentEntry={setCurrentEntry}
                />
              )}
            </Box>
          </Grid>
        </Grid>
      )}
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={saveStatus.visible}
        autoHideDuration={6000}
        onClose={() => setSaveStatus({ ...saveStatus, visible: false })}
      >
        <Alert
          onClose={() => setSaveStatus({ ...saveStatus, visible: false })}
          severity={saveStatus.success ? "success" : "error"}
          sx={{ width: '100%' }}
        >
          {saveStatus.message}
        </Alert>
      </Snackbar>
      {geoPopupPhotos && (
        <PhotoPopupWithGeo photos={geoPopupPhotos} closePhotoPopup={closeGeoPhotoPopup} fetchMorePlacesForGroup={fetchMorePlacesForGroup} fetchPlaceForGroup={fetchPlaceForGroup} />
      )}
    </Container>
  );
};

export default App;
