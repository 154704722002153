// src/components/DiffPopup.js
import React, { useRef, useEffect } from "react";
import { Typography, Button } from "@mui/material";
import "./DiffPopup.css"; // Import the CSS for diff highlighting

const DiffPopup = ({ original, corrected, onAccept, onReject }) => {
  const originalRef = useRef(null);
  const correctedRef = useRef(null);

  const getDiff = () => {
    const originalWords = original.split(" ");
    const correctedWords = corrected.split(" ");

    const lcsMatrix = Array(originalWords.length + 1)
      .fill(null)
      .map(() => Array(correctedWords.length + 1).fill(0));

    for (let i = 1; i <= originalWords.length; i++) {
      for (let j = 1; j <= correctedWords.length; j++) {
        if (originalWords[i - 1] === correctedWords[j - 1]) {
          lcsMatrix[i][j] = lcsMatrix[i - 1][j - 1] + 1;
        } else {
          lcsMatrix[i][j] = Math.max(lcsMatrix[i - 1][j], lcsMatrix[i][j - 1]);
        }
      }
    }

    const originalDiff = [];
    const correctedDiff = [];
    let i = originalWords.length;
    let j = correctedWords.length;

    while (i > 0 && j > 0) {
      if (originalWords[i - 1] === correctedWords[j - 1]) {
        originalDiff.unshift(<span key={`o${i}-${j}`}>{originalWords[i - 1]} </span>);
        correctedDiff.unshift(<span key={`c${i}-${j}`}>{correctedWords[j - 1]} </span>);
        i--;
        j--;
      } else if (lcsMatrix[i - 1][j] >= lcsMatrix[i][j - 1]) {
        originalDiff.unshift(<span key={`o${i}-${j}`} style={{ backgroundColor: "#ffcccc" }}>{originalWords[i - 1]} </span>);
        i--;
      } else {
        correctedDiff.unshift(<span key={`c${i}-${j}`} style={{ backgroundColor: "#ccffcc" }}>{correctedWords[j - 1]} </span>);
        j--;
      }
    }

    while (i > 0) {
      originalDiff.unshift(<span key={`o${i}`} style={{ backgroundColor: "#ffcccc" }}>{originalWords[i - 1]} </span>);
      i--;
    }

    while (j > 0) {
      correctedDiff.unshift(<span key={`c${j}`} style={{ backgroundColor: "#ccffcc" }}>{correctedWords[j - 1]} </span>);
      j--;
    }

    return { originalDiff, correctedDiff };
  };

  const { originalDiff, correctedDiff } = getDiff();

  const syncScroll = (e) => {
    if (originalRef.current && correctedRef.current) {
      if (e.target === originalRef.current) {
        correctedRef.current.scrollTop = originalRef.current.scrollTop;
      } else {
        originalRef.current.scrollTop = correctedRef.current.scrollTop;
      }
    }
  };

  useEffect(() => {
    const originalElement = originalRef.current;
    const correctedElement = correctedRef.current;

    if (originalElement && correctedElement) {
      originalElement.addEventListener("scroll", syncScroll);
      correctedElement.addEventListener("scroll", syncScroll);
    }

    return () => {
      if (originalElement && correctedElement) {
        originalElement.removeEventListener("scroll", syncScroll);
        correctedElement.removeEventListener("scroll", syncScroll);
      }
    };
  }, []);

  return (
    <div className="popup">
      <div className="popup-content">
        <div className="popup-header">
          <Typography variant="h6">Text Comparison</Typography>
        </div>
        <div className="popup-body">
          <div ref={originalRef}>
            <Typography variant="subtitle1">Original</Typography>
            <Typography>{originalDiff}</Typography>
          </div>
          <div ref={correctedRef}>
            <Typography variant="subtitle1">Corrected</Typography>
            <Typography>{correctedDiff}</Typography>
          </div>
        </div>
        <div className="popup-footer" style={{ display: 'flex', justifyContent: 'space-between', paddingTop: '10px' }}>
          <Button variant="contained" color="primary" onClick={onAccept}>
            Accept
          </Button>
          <Button variant="contained" color="secondary" onClick={onReject}>
            Reject
          </Button>
        </div>
      </div>
    </div>
  );
};

export default DiffPopup;
