// src/entriesApi.js

import { db, auth } from "./firebase";
import { collection, query, where, getDocs } from "firebase/firestore";
import { fetchPhotosByIds } from "./utils/googlePhotosApi";

export const fetchEntries = async () => {
  const user = auth.currentUser;
  if (user) {
    const q = query(collection(db, `users/${user.uid}/entries`), where("userId", "==", user.uid));
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
  }
  return [];
};

export const fetchEntryByDate = async (date) => {
  const user = auth.currentUser;
  if (user) {
    const startOfDay = new Date(date);
    startOfDay.setHours(0, 0, 0, 0);
    const endOfDay = new Date(date);
    endOfDay.setHours(23, 59, 59, 999);

    const q = query(
      collection(db, `users/${user.uid}/entries`),
      where("userId", "==", user.uid),
      where("createdAt", ">=", startOfDay),
      where("createdAt", "<=", endOfDay)
    );
    const querySnapshot = await getDocs(q);
    if (!querySnapshot.empty) {
      const doc = querySnapshot.docs[0];
      let entry = { id: doc.id, ...doc.data() };

      // Load photo details
      if (entry.photos && entry.photos.length > 0) {
        const photos = await fetchPhotosByIds(entry.photos.map(p => p.id));
        entry.photos = photos.map(photo => {
          const firestorePhotoData = entry.photos.find(p => p.id === photo.id);
          if (firestorePhotoData) {
            photo.date_taken = firestorePhotoData.date_taken;
            photo.filename = firestorePhotoData.filename;
            if (firestorePhotoData.latitude && firestorePhotoData.longitude) {
              photo.latitude = firestorePhotoData.latitude;
              photo.longitude = firestorePhotoData.longitude;
            }
            if (firestorePhotoData.place) {
              photo.place = firestorePhotoData.place;
            }
          }
          return photo;
        });
      }

      return entry;
    }
  }
  return null;
};
